export const DENIED_MAIL_DOMAINS = [
  'wbx.ai',
  // 'mailinator.com', To run cypress test, we have commented mailinator from Denied_Mail_Domains.
  'carehybrid.com',
  // 'maildrop.cc',
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'aol.com',
  'icloud.com',
  'zoho.com',
  'yandex.com',
  'protonmail.com',
  'mail.com',
  'gmx.com',
  'inbox.com',
  'gmx.us',
  'gmx.de',
  'gmx.net',
  'gmx.at',
  'gmx.ch',
  'gmx.fr',
  'gmx.c',
  'gmx.co.uk',
  'gmx.com.mx',
  'gmx.es',
  'gmx.eu',
  'gmx.com.br',
  'gmx.com.ar',
  'gmx.com.co',
  'gmx.com.ve',
  'gmx.com.tr',
  'gmx.com.pl',
  'gmx.com.pt',
  'gmx.com.ua',
  'gmx.com.vn',
  'gmx.com.ph',
  'gmx.com.au',
  'gmx.com.tw',
  'gmx.com.hk',
  'gmx.com.sg',
  'gmx.com.my',
  'gmx.com.id',
  'gmx.com.sa',
  'gmx.com.pe',
]
