import React, { useState } from 'react'
import {
  Button,
  Checkbox,
  Icon,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@momentum-ui/react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import httpService from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import IconButton from '../../IconButton'
import Link from '../../Link'
import { ISandbox } from '../index'
import MetricsService from '../../../services/metricsService'
import { SANDBOX_ACTION } from '../../../constants/metrics'
import { getCIUrls } from '../../../utils/auth.utils'
import { retrieveFeatureFlags } from '../../../state/auth'
import { useSelector } from 'react-redux'
import { AppState } from '../../../state/store'

const SANDBOX_AUTO_ENABLED = 'devportal-sandbox-provisioning-status'

interface IListSandboxModalProps {
  handleClose: Function
  email: string
  bearerToken: string
  sandboxList: ISandbox[]
  setNextSandboxToShow: Function
  setSelectedSandboxOrgId: Function
  fetchSandboxData: Function
  setOrder: Function
}

const ListSandboxModal: React.FC<IListSandboxModalProps> = ({
  handleClose,
  email,
  sandboxList,
  bearerToken,
  setNextSandboxToShow,
  setSelectedSandboxOrgId,
  fetchSandboxData,
  setOrder,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const userHasSandbox = sandboxList.length > 0
  const [
    userAcceptedTermsAndConditions,
    setUserAcceptedTermsAndConditions,
  ] = useState(false)
  const [isSendingRequest, setIsSendingRequest] = useState(false)

  const createExpirationDate = (
    created: string,
    maxAgeDays: number | undefined
  ): string | React.ReactNode => {
    if (maxAgeDays) {
      const createdDate = dayjs(created)
      const currentDate = dayjs(new Date())
      const expirationDate = createdDate.add(maxAgeDays, 'day')
      const daysRemainingBeforeExpiration = Math.ceil(
        expirationDate.diff(currentDate, 'day')
      )
      if (daysRemainingBeforeExpiration < 0) {
        return <span className="expired-sandbox">{t('sandbox.expired')}</span>
      } else {
        return `${t('sandbox.expiresIn')} ${daysRemainingBeforeExpiration} ${t(
          'sandbox.days'
        )} `
      }
    } else {
      return null
    }
  }

  const hasSandboxAutoEnabled = useSelector((state: AppState) => {
    return retrieveFeatureFlags(state)[SANDBOX_AUTO_ENABLED] === 'on'
  })
  const createSandboxRequest = async (): Promise<void> => {
    const { identityUrl } = getCIUrls()
    try {
      setIsSendingRequest(true)
      const res = await httpService.post(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox`,
        { identityHost: identityUrl },
        {
          headers: { Authorization: `Bearer ${bearerToken}` },
        }
      )
      const orders = res.data.orders
      setOrder(orders)
      fetchSandboxData()
      metricsService.track(SANDBOX_ACTION, {
        type: 'Create Sandbox Request',
      })
      setIsSendingRequest(false)
      if (hasSandboxAutoEnabled) {
        setNextSandboxToShow('progress')
      } else {
        setNextSandboxToShow('success')
      }
    } catch (err) {
      setIsSendingRequest(false)
      logError(`the call to create sandbox failed due to ${err}`)
      setNextSandboxToShow('error')
    }
  }

  const handleToSLabelInteraction = (e: React.KeyboardEvent): void => {
    // enter, space or clicking on label should change the checkbox
    if (e.key === ' ' || e.key === 'Enter' || !e.key) {
      setUserAcceptedTermsAndConditions(!userAcceptedTermsAndConditions)
    }
  }

  return (
    <>
      <ModalHeader headerLabel={`${t('sandbox.sandboxListModalHeader')}`} />
      <ModalBody className="sandbox-modal-body-alt" data-cy="list-modal">
        <p className="sandbox-modal-body-text">{`${t(
          'sandbox.sandboxListModalBodyP1'
        )}`}</p>
        <p className="sandbox-modal-body-text">
          {`${t('sandbox.sandboxListModalBodyP2')}`}
          <span className="sandbox-emphasis-text">
            {email.split('@')[1] || email}
          </span>
          <span>{t('sandbox.sandboxListModalBodyP21')}</span>
        </p>
        <ul className="sandbox-list">
          {sandboxList.length ? (
            sandboxList.map((sandbox, i) => {
              return (
                <li key={i} className="sandbox-list-item">
                  <div>
                    <div className="sandbox-emphasis-text">
                      {sandbox.displayName}
                    </div>
                    <div className="sandbox-org-id">
                      {' '}
                      <span className="sandbox-emphasis-text">
                        Org ID:
                      </span>{' '}
                      {sandbox.customerOrgId}
                    </div>
                  </div>

                  <div
                    className="sandbox-center-align"
                    data-cy={`expiration-date-${i}`}
                  >
                    {createExpirationDate(sandbox.created, sandbox.maxAgeDays)}
                  </div>

                  <div className="sandbox-center-align">
                    <IconButton
                      tooltipText={t('sandbox.clickToDelete')}
                      onClick={(): void => {
                        setSelectedSandboxOrgId(sandbox.customerOrgId)
                        setNextSandboxToShow('delete')
                      }}
                      icon={<Icon name="icon-delete_14" />}
                      data-cy="delete-btn"
                      btnClassName="sandbox-delete-btn"
                    />
                  </div>
                </li>
              )
            })
          ) : (
            <li className="sandbox-list-item">{`${t(
              'sandbox.noSandboxes'
            )}`}</li>
          )}
        </ul>
        <Checkbox
          className="sandbox-tos-checkbox"
          value="agreement-checkbox"
          checked={userAcceptedTermsAndConditions || userHasSandbox}
          htmlId={'acceptStatement'}
          onClick={(): void => {
            setUserAcceptedTermsAndConditions(!userAcceptedTermsAndConditions)
          }}
          data-cy="tos-checkbox"
          disabled={userHasSandbox}
        >
          <span
            onClick={(e: React.MouseEvent): void => {
              setUserAcceptedTermsAndConditions(!userAcceptedTermsAndConditions)
            }}
            onKeyDown={handleToSLabelInteraction}
            role="checkbox"
            aria-checked={userAcceptedTermsAndConditions}
            tabIndex={0}
            id="acceptance-label"
            data-cy="tos-checkbox-label"
            className={userHasSandbox ? 'md-font-color--disabled' : ''}
          >
            {!userHasSandbox
              ? t('integrations.acceptStatement')
              : t('integrations.acceptedStatement')}
            <Link
              to="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('integrations.termsOfService')}
            </Link>
            .
          </span>
        </Checkbox>
        <div />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={(): void => {
            handleClose()
          }}
          ariaLabel={`${t('actionButtons.cancel')}`}
          data-cy="close-btn"
          color={!userHasSandbox ? 'default' : 'blue'}
        >
          {`${t('actionButtons.cancel')}`}
        </Button>
        {!userHasSandbox ? (
          <Button
            onClick={createSandboxRequest}
            ariaLabel={`${t('sandbox.sandboxPageBtnProvision')}`}
            color="blue"
            disabled={!userAcceptedTermsAndConditions}
            data-cy="submit-btn"
            loading={isSendingRequest}
          >
            {`${t('sandbox.sandboxPageBtnProvision')}`}
          </Button>
        ) : null}
      </ModalFooter>
    </>
  )
}

export default ListSandboxModal
