import { Modal } from '@momentum-ui/react'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import UserLoginModal from './UserLoginModal'
import ListSandboxModal from './ListSandboxModal'
import SandboxResponse from './SandboxResponse'
import SandboxInProgress from './SandboxInProgress'
import DeleteConfirmationModal from './DeleteConfirmation'
import { retrieveToken } from '../../state/auth'
import { AppState } from '../../state/store'
import SandboxErrorResponse from './SandboxErrorResponse'
import RequestSandboxModal from './RequestSandboxModal'
import DeniedSandbox from './DeniedSandboxModal'

export interface ISandbox {
  created: string
  customerOrgId: string
  displayName: string
  maxAgeDays?: number
}
interface ISandboxModalsProps {
  email?: string
  sandboxList: ISandbox[]
  handleClose: Function
  isModalShowing: boolean
  visibleModal: string
  setVisibleModal: Function
  fetchSandboxData: Function
}

const SandboxModals: React.FC<ISandboxModalsProps> = ({
  email,
  sandboxList,
  handleClose,
  isModalShowing,
  visibleModal,
  setVisibleModal,
  fetchSandboxData,
}) => {
  const userAuthToken = useSelector((state: AppState) => retrieveToken(state))
  const [selectedSandboxOrgId, setSelectedSandboxOrgId] = useState(null)
  const [order, setOrder] = useState({})

  const modalSizeMap: { [key: string]: string } = {
    delete: 'small',
    deleteSuccess: 'small',
    error: 'small',
    ListSandboxModal: 'medium',
    login: 'small',
    requestSandbox: 'medium',
    success: 'small',
    deniedSandbox: 'small',
    progress: 'large',
  }

  const possibleModalsMap: { [key: string]: React.ReactNode } = {
    delete: (
      <DeleteConfirmationModal
        setNextSandboxToShow={setVisibleModal}
        bearerToken={userAuthToken}
        selectedSandboxOrgId={selectedSandboxOrgId}
        fetchSandboxData={fetchSandboxData}
      />
    ),
    deleteSuccess: (
      <SandboxResponse handleClose={handleClose} isCreate={false} />
    ),
    error: <SandboxErrorResponse handleClose={handleClose} />,
    listSandboxes: (
      <ListSandboxModal
        handleClose={handleClose}
        email={email as string}
        setNextSandboxToShow={setVisibleModal}
        bearerToken={userAuthToken}
        sandboxList={sandboxList}
        setSelectedSandboxOrgId={setSelectedSandboxOrgId}
        fetchSandboxData={fetchSandboxData}
        setOrder={setOrder}
      />
    ),
    login: <UserLoginModal />,
    success: <SandboxResponse handleClose={handleClose} isCreate={true} />,
    progress: (
      <SandboxInProgress
        handleClose={handleClose}
        setNextSandboxToShow={setVisibleModal}
        fetchSandboxData={fetchSandboxData}
      />
    ),
    requestSandbox: <RequestSandboxModal handleClose={handleClose} />,
    deniedSandbox: (
      <DeniedSandbox handleClose={handleClose} email={email as string} />
    ),
  }
  return (
    <Modal
      applicationId="dev-portal"
      onHide={(): void => handleClose()}
      show={isModalShowing}
      htmlId="sandbox-modal"
      className="sandbox-modal"
      size={modalSizeMap[visibleModal]}
    >
      {possibleModalsMap[visibleModal]}
    </Modal>
  )
}

export default SandboxModals
