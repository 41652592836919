import React, { useEffect, useState } from 'react'
import {
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert,
  AlertContainer,
} from '@momentum-ui/react'
import httpService from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import { useTranslation } from 'react-i18next'
import { getCIUrls } from '../../../utils/auth.utils'
import { useSelector } from 'react-redux'
import { AppState } from '../../../state/store'
import { retrieveToken } from '../../../state/auth'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Stepper, Step } from 'react-form-stepper'
import SandboxProgress from '../../../assets/images/provision_sandbox_progress.png'
import { StepStyleDTO } from 'react-form-stepper/dist/components/Step/StepTypes'

const PROCESS_STATUS = 'process'
const WAIT_STATUS = 'wait'
const COMPLETE_STATUS = 'complete'
const FINISH_STATUS = 'finish'
const FAIL_STATUS = 'fail'
const FAILED_STATUS = 'failed'
const MAX_RETRIES = 3
const RETRY_COUNT = 0

interface ISandboxResponseProps {
  handleClose: Function
  setNextSandboxToShow: Function
  fetchSandboxData: Function
}

type Task = {
  taskPath: string
  status: string
}

type Order = {
  created: string
  customerOrgId: string
  displayName: string
  maxAgeDays?: number
}

const SandboxInProgress: React.FC<ISandboxResponseProps> = ({
  handleClose,
  setNextSandboxToShow,
  fetchSandboxData,
}) => {
  const { t } = useTranslation()
  const [step, setStep] = useState(0)
  const [status, setStatus] = useState([
    PROCESS_STATUS,
    WAIT_STATUS,
    WAIT_STATUS,
    WAIT_STATUS,
    WAIT_STATUS,
  ])
  const [started, setStarted] = useState('')
  const [updated, setUpdated] = useState('')
  const [complete, setComplete] = useState(false)
  const [showSuccessAlert, setShowSuccessAlert] = useState(false)
  const [showPopup, setShowPopup] = useState(0)
  const [orderId, setOrderId] = useState(null)
  const [hasFailed, setHasFailed] = useState(false)
  const SANDBOX_CREATE = 'CREATE'
  const WAITING = 'waiting'
  const authToken = useSelector((state: AppState) => retrieveToken(state))
  const [isSendingRequest, setIsSendingRequest] = useState(false)
  const { identityUrl } = getCIUrls()
  const [isDeleteFirstTime, setIsDeleteFirstTime] = useState(true)
  let orderState = ''
  let deleteOrderId = ''

  interface SandboxTask {
    taskPath: string
    status: string
  }

  interface SandboxOrderDetails {
    tasks: SandboxTask[]
    state: string
  }
  const retrySandboxOrder = async (): Promise<void> => {
    try {
      await deleteSandboxRequest()
      await createSandboxRequest()
    } catch (error) {
      logError(`Error during retrySandboxOrder request ${error}`)
      setNextSandboxToShow('error')
    }
  }

  const getSandboxOrderDetailsByOrderID = async (
    deleteOrderId: string
  ): Promise<SandboxOrderDetails[]> => {
    try {
      setIsSendingRequest(true)
      const res = await httpService.get(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/orders/${deleteOrderId}`,
        createRequestObject(identityUrl, authToken)
      )
      setIsSendingRequest(false)
      return res.data
    } catch (err) {
      setIsSendingRequest(false)
      logError(
        `the call to getSandboxOrderDetailsByOrderID failed due to ${err}`
      )
      return []
    }
  }
  const verifyOrderStateAndRetryDeleteSandbox = (
    customerOrgId: string,
    RETRY_COUNT: number
  ): void => {
    const intervalId = setInterval(async () => {
      try {
        if (orderState === WAITING) {
          const orderDetails = await getSandboxOrderDetailsByOrderID(
            deleteOrderId
          )
          if (orderDetails.length > 0) {
            orderState = orderDetails[0].state
          }
        } else if (orderState === FAILED_STATUS && RETRY_COUNT <= MAX_RETRIES) {
          clearInterval(intervalId)
          RETRY_COUNT = RETRY_COUNT + 1
          await submitDeleteSandboxOrder(customerOrgId, RETRY_COUNT)
        } else {
          clearInterval(intervalId)
        }
      } catch (err) {
        clearInterval(intervalId)
        logError(`Error during verifyOrderStateAndRetryDeleteSandbox ${err}`)
      }
    }, 15000)
  }

  const submitDeleteSandboxOrder = async (
    customerOrgId: string,
    RETRY_COUNT: number
  ): Promise<void> => {
    if (orderState !== COMPLETE_STATUS) {
      if (isDeleteFirstTime || orderState === FAILED_STATUS) {
        await deleteSandbox(customerOrgId)
        setIsDeleteFirstTime(false)

        const orderDetails = await getSandboxOrderDetailsByOrderID(
          deleteOrderId
        )
        if (orderDetails.length > 0) {
          orderState = orderDetails[0].state
        }

        if (orderState === WAITING || orderState === FAILED_STATUS) {
          verifyOrderStateAndRetryDeleteSandbox(customerOrgId, RETRY_COUNT)
        }
      }
    }
  }

  const createSandboxRequest = async (): Promise<void> => {
    try {
      setIsSendingRequest(true)
      const res = await httpService.post(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox`,
        { identityHost: identityUrl },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      setHasFailed(false)
      const orders = res.data.orders
      setIsSendingRequest(false)
      setOrderId(orders.orderId)
      setNextSandboxToShow('progress')
    } catch (err) {
      setIsSendingRequest(false)
      logError(`the call to create sandbox failed due to ${err}`)
    }
  }
  const deleteSandbox = async (customerOrgId: string): Promise<void> => {
    try {
      setIsSendingRequest(true)
      const res = await httpService.delete(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/${customerOrgId}`,
        {
          data: { identityHost: identityUrl },
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
      const orders = res.data.orders
      deleteOrderId = orders.orderId
      setIsSendingRequest(false)
    } catch (err) {
      setIsSendingRequest(false)
      logError(`the call to delete sandbox failed due to ${err}`)
    }
  }
  const deleteSandboxRequest = async (): Promise<void> => {
    try {
      setIsSendingRequest(true)
      const res = await httpService.get(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox`,
        createRequestObject(identityUrl, authToken)
      )
      const sandboxList = res.data
      await Promise.all(
        sandboxList.map(async (order: Order) => {
          if (order.customerOrgId) {
            await submitDeleteSandboxOrder(order.customerOrgId, RETRY_COUNT)
          }
        })
      )
      setIsSendingRequest(false)
    } catch (err) {
      setIsSendingRequest(false)
      logError(`the call to delete sandbox failed due to ${err}`)
    }
  }
  const createRequestObject = (
    identityUrl: string,
    authToken: string
  ): any => ({
    params: { identityHost: identityUrl },
    headers: { Authorization: `Bearer ${authToken}` },
  })

  const convertUtcToLocalTime = (utcDateString: string): string =>
    dayjs.utc(utcDateString).local().format('MM-DD-YYYY HH:mm:ss')

  const formatDateTime = (dateTime: string): string =>
    dayjs(dateTime).format('MM-DD-YYYY HH:mm:ss')

  const dateAndTime = (dateTime: string): string => formatDateTime(dateTime)
  dayjs.extend(utc)

  const getData = async (): Promise<void> => {
    try {
      const updatedStatus = [...status]

      const orderResponse = await httpService.get(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/order`,
        createRequestObject(identityUrl, authToken)
      )
      const orders = orderResponse.data
      const createSandboxOrders = orders.filter(
        (order: any) => order.type === SANDBOX_CREATE && order.state === WAITING
      )

      let res
      if (createSandboxOrders.length > 0) {
        const firstOrder = createSandboxOrders[createSandboxOrders.length - 1]
        setOrderId(firstOrder.orderId)

        res = await httpService.get(
          `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/orders/${firstOrder.orderId}`,
          createRequestObject(identityUrl, authToken)
        )
      } else if (orderId) {
        res = await httpService.get(
          `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/orders/${orderId}`,
          createRequestObject(identityUrl, authToken)
        )
      } else {
        logError(`No orders found and no stored orderId.`)
        setNextSandboxToShow('error')
        return
      }
      const tasks = res.data[0].tasks

      tasks.forEach((task: Task, index: number) => {
        if (task.status === COMPLETE_STATUS) {
          switch (task.taskPath) {
            case 'cortexSandbox_resource_webex_wcc_dev_portal_create':
              updatedStatus[index] = FINISH_STATUS
              setStep(1)
              break
            case 'cortexSandbox_resource_webex_wcc_provision_admin_v2':
              updatedStatus[index] = FINISH_STATUS
              setStep(2)
              break
            case 'cortexSandbox_resource_webex_wxcc_provision_numbers':
              updatedStatus[index] = FINISH_STATUS
              setStep(3)
              break
            case 'cortexSandbox_resource_webex_wxcc_initial_config':
              updatedStatus[index] = FINISH_STATUS
              setStep(4)
              break
            case 'wxcc_advanced_configuration_notification_v2':
              updatedStatus[index] = FINISH_STATUS
              setStep(5)
              break
          }
        } else if (task.status === FAILED_STATUS) {
          updatedStatus[index] = FAIL_STATUS
          setHasFailed(true)
        }
      })

      setStatus(updatedStatus)
      const utcDateString = orders[0].createdDate
      const createdLocalTime = convertUtcToLocalTime(utcDateString)
      setStarted(createdLocalTime)
      setUpdated(dateAndTime(dayjs.utc().format()))
      if (orders.state === COMPLETE_STATUS) {
        setComplete(true)
      }
    } catch (err) {
      logError(`the call to sandbox order details has failed due to ${err}`)
    }
  }
  useEffect(() => {
    getData()
  }, [authToken, orderId])

  useEffect(() => {
    const intervalCall = setInterval(() => {
      getData()
    }, 3000)

    return (): void => {
      clearInterval(intervalCall)
    }
  }, [step, status, started, updated])
  useEffect(() => {
    const allStepsComplete = status.every(
      (areAllStepsComplete) => areAllStepsComplete === FINISH_STATUS
    )
    if (allStepsComplete) {
      setShowPopup(showPopup + 1)
    }
    if (showPopup === 1) {
      setShowSuccessAlert(true)
    }
  }, [status])

  const handleCloseAlert = (): void => {
    setShowSuccessAlert(false)
  }

  return (
    <>
      <div className="sandbox-scroll">
        <ModalHeader headerLabel={t('sandbox.progress')} />
        <ModalBody className="sandbox-modal-body">
          <div className="modal-progress">
            <Stepper
              activeStep={step}
              styleConfig={
                {
                  activeBgColor: hasFailed ? '#FF6961' : '#31E88C',
                  completedBgColor: '#00AB50',
                  inactiveBgColor: '#eee',
                } as StepStyleDTO
              }
            >
              <Step label={t('sandbox.sandboxWxccCreate')} />
              <Step label={t('sandbox.sandboxProvisionAdminV2')} />
              <Step label={t('sandbox.sandboxPstnConfig')} />
              <Step label={t('sandbox.sandboxConfigApis')} />
              <Step label={t('sandbox.sandboxNotification')} />
            </Stepper>
            <hr />
            <div>
              <div className="row">
                <div className="columns medium-6">
                  <p>
                    {t('sandbox.sandboxProcessStarted')} {started}
                  </p>
                </div>
                <div className="columns medium-6">
                  <p>
                    {t('sandbox.sandboxLastUpdated')} {updated.toString()}
                  </p>
                </div>
              </div>
            </div>

            <div className="progress-image">
              <img src={SandboxProgress} alt="progress" />
            </div>
            <p>
              {t('sandbox.sandboxOrderId')} <b>{orderId ? orderId : null}</b>
            </p>
            <hr />
          </div>
          <p
            className="sandbox-response-text notification-text"
            data-cy="submitted-success"
          >
            {t('sandbox.progressMessage')}
            <b> {t('sandbox.progressMessageBold')}</b>
          </p>
        </ModalBody>
        <ModalFooter>
          {hasFailed && (
            <Button
              onClick={retrySandboxOrder}
              ariaLabel={t('actionButtons.retry')}
              color="gray"
              data-cy="retry-btn"
              loading={isSendingRequest}
            >
              {t('actionButtons.retry')}
            </Button>
          )}
          <Button
            onClick={(): void => {
              handleClose()
              fetchSandboxData()
            }}
            ariaLabel={t('actionButtons.close')}
            color="blue"
            data-cy="close-btn"
          >
            {t('actionButtons.close')}
          </Button>
        </ModalFooter>
      </div>
      <AlertContainer>
        {showSuccessAlert && (
          <Alert
            closable
            title={t('sandbox.alert')}
            message={t('sandbox.sandboxAlertMessage')}
            dismissBtnProps={{
              onClick: handleCloseAlert,
              ariaLabel: t('actionButtons.closeAlert'),
            }}
            show={showSuccessAlert}
            type={t('sandbox.success')}
          />
        )}
      </AlertContainer>
    </>
  )
}

export default SandboxInProgress
