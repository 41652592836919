import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { login } from '../../../utils/auth.utils'

const UserLoginModal: React.FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader headerLabel={`${t('sandbox.userNotLoggedInModalHeader')}`} />
      <ModalBody className="sandbox-modal-body">
        <p className="sandbox-modal-body-text">{`${t(
          'sandbox.userNotLoggedInModalBody'
        )}`}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={(): void => {
            login()
          }}
          ariaLabel={t('header.login')}
          color="blue"
          data-cy="login-btn"
        >
          {t('header.login')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default UserLoginModal
