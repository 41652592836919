import React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import FlagSvg from '../../../assets/svgs/flag-success.svg'

interface ISandboxResponseProps {
  handleClose: Function
  isCreate: boolean
}

const SandboxResponse: React.FC<ISandboxResponseProps> = ({
  handleClose,
  isCreate,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader headerLabel={t('sandbox.submitted')} />
      <ModalBody className="sandbox-modal-body" data-cy="modal-success">
        <FlagSvg />

        {isCreate ? (
          <p className="sandbox-response-text" data-cy="submitted-success">
            {t('sandbox.sandboxSubmitted')}
          </p>
        ) : (
          <p className="sandbox-response-text" data-cy="deleted-success">
            {t('sandbox.sandboxDeleteSubmitted')}
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={(): void => {
            handleClose()
          }}
          ariaLabel={t('actionButtons.close')}
          color="blue"
          data-cy="close-btn"
        >
          {t('actionButtons.close')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default SandboxResponse
