import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { Button, AlertBanner } from '@momentum-ui/react'
import Seo from '../components/Seo'
import DocumentationLayout from '../layouts/DocumentationLayout'
import { PAGE_VIEWED } from '../constants/metrics'
import MetricsService from '../services/metricsService'
import GuideMarkdown from '../components/GuideMarkdown'
import {
  retrieveFeatureFlags,
  retrieveToken,
  retrieveUser,
} from '../state/auth'
import { AppState } from '../state/store'
import httpService from '../services/httpService'
import { getBffBaseUrl } from '../services/configService'
import { logError } from '../services/loggerService'
import SandboxModals from '../components/SandboxModals'
import { User } from '../interfaces/ciInterfaces'
import { ISandbox, ISandboxOrder } from '../interfaces/sandboxIntertfaces'
import { getCIUrls } from '../utils/auth.utils'
import { DENIED_MAIL_DOMAINS } from '../constants/denyMailList'

const SANDBOX_FEATURE_FLAG = 'devportal-sandbox'
const SANDBOX_AUTO_ENABLED = 'devportal-sandbox-provisioning-status'
const SANDBOX_BANNER = 'devportal-sandbox-banner'

interface IFeaturePageProps {
  data: {
    strapiFeaturePages: {
      name: string
      content: any
    }
  }
}

export const query = graphql`
  query FeaturePageQuery($name: String!) {
    strapiFeaturePages(name: { eq: $name }) {
      name
      content
    }
  }
`

const Sandbox: React.FC<IFeaturePageProps> = ({ data }): JSX.Element => {
  const { name, content } = data.strapiFeaturePages
  const metricsService = MetricsService.getInstance()
  const { t } = useTranslation()

  metricsService.track(PAGE_VIEWED, {
    pageName: name,
  })

  const authToken = useSelector((state: AppState) => retrieveToken(state))
  const user = useSelector((state: AppState) => retrieveUser(state))
  const userEmail =
    (user as User).emails?.find(({ primary }) => !!primary)?.value || ''
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [sandboxList, setSandboxList] = useState<ISandbox[]>([])
  const [pendingSandboxOrders, setPendingSandboxOrders] = useState<
    ISandboxOrder[]
  >([])
  const [visibleModal, setVisibleModal] = useState(
    userEmail ? 'listSandboxes' : 'email'
  )
  const hasSandboxFeatureFlag = useSelector((state: AppState) => {
    return retrieveFeatureFlags(state)[SANDBOX_FEATURE_FLAG] === 'on'
  })

  const hasSandboxAutoEnabled = useSelector((state: AppState) => {
    return retrieveFeatureFlags(state)[SANDBOX_AUTO_ENABLED] === 'on'
  })

  const hasSandboxOutage = useSelector((state: AppState) => {
    return retrieveFeatureFlags(state)[SANDBOX_BANNER] === 'on'
  })

  const isPersonalEmail = (email: string): boolean => {
    const emailDomain = email.split('@')[1]
    return DENIED_MAIL_DOMAINS.some(
      (domain) => emailDomain === domain || emailDomain.endsWith(domain)
    )
  }
  const SANDBOX_CREATE = 'CREATE'
  const SANDBOX_DELETE = 'DELETE'
  const WAITING = 'waiting'
  const createSandboxOrders = pendingSandboxOrders.filter(
    (order) => order.type === SANDBOX_CREATE && order.state === WAITING
  )

  const deleteSandboxOrders = pendingSandboxOrders.filter(
    (order) => order.type === SANDBOX_DELETE && order.state === WAITING
  )

  let sandboxBtnText = ''

  if (createSandboxOrders.length && hasSandboxAutoEnabled) {
    sandboxBtnText = t('sandbox.sandboxPageBtnRequestInProgress')
  } else if (deleteSandboxOrders.length && hasSandboxAutoEnabled) {
    sandboxBtnText = t('sandbox.sandboxPageBtnDeleteInProgress')
  } else if (
    ((hasSandboxAutoEnabled && !createSandboxOrders.length) ||
      hasSandboxFeatureFlag) &&
    sandboxList.length
  ) {
    sandboxBtnText = t('sandbox.sandboxPageBtnManage')
  } else if (hasSandboxFeatureFlag) {
    sandboxBtnText = t('sandbox.sandboxPageBtnProvision')
  } else {
    sandboxBtnText = t('sandbox.sandboxPageBtnRequest')
  }

  const sandboxModalToRender = (): string => {
    if (!userEmail) {
      return 'login'
    } else if (isPersonalEmail(userEmail) && !hasSandboxFeatureFlag) {
      return 'deniedSandbox'
    } else if (!hasSandboxFeatureFlag) {
      return 'requestSandbox'
    } else if (
      hasSandboxAutoEnabled &&
      (createSandboxOrders.length ||
        (!sandboxList.length && createSandboxOrders.length))
    ) {
      return 'progress'
    } else if (!sandboxList.length && createSandboxOrders.length) {
      return 'success'
    } else if (sandboxList.length && deleteSandboxOrders.length) {
      return 'deleteSuccess'
    } else if (!createSandboxOrders.length || !deleteSandboxOrders.length) {
      return 'listSandboxes'
    } else {
      return 'error'
    }
  }

  const fetchSandboxList = async (): Promise<void> => {
    const { identityUrl } = getCIUrls()
    const sandboxRequest = async (endpoint: string): Promise<any> => {
      return await httpService.get(endpoint, {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          identityHost: identityUrl,
        },
      })
    }
    try {
      if (userEmail && hasSandboxFeatureFlag) {
        setIsLoading(true)
        const responses = await Promise.all([
          sandboxRequest(`${getBffBaseUrl()}/v1/sandbox`),
          sandboxRequest(`${getBffBaseUrl()}/v1/sandbox/order`),
        ])

        const [sandboxListData, sandboxOrderData] = responses.map(
          (response) => response.data
        )
        setSandboxList(sandboxListData)
        setPendingSandboxOrders(sandboxOrderData)
      }
    } catch (err) {
      logError(`An error has occurred while fetching sandboxes ${err}`)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchSandboxList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  return (
    <DocumentationLayout>
      <Seo title={name} />
      <div className="sandbox-wrapper">
        <section className="guide-container-header">
          {hasSandboxOutage && (
            <AlertBanner
              className="sandbox-outage-banner"
              show={hasSandboxOutage}
              type="error"
            >
              {t('sandbox.outageBanner')}
            </AlertBanner>
          )}
          <h1>{t('sandbox.sandboxPageHeader')}</h1>
          <Button
            className="modal-btn"
            onClick={(): void => {
              setIsModalShowing(true)
              setVisibleModal(sandboxModalToRender)
            }}
            data-cy="sandbox-modal-btn"
            color="blue"
            size={28}
            ariaLabel={t('sandbox.sandboxPageHeader')}
            loading={isLoading}
            disabled={hasSandboxOutage}
          >
            {sandboxBtnText}
          </Button>
        </section>
        <GuideMarkdown content={content} />
      </div>
      <SandboxModals
        sandboxList={sandboxList}
        handleClose={(): void => setIsModalShowing(false)}
        isModalShowing={isModalShowing}
        email={userEmail}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        fetchSandboxData={fetchSandboxList}
      />
    </DocumentationLayout>
  )
}

export default Sandbox
