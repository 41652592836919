import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import httpService from '../../../services/httpService'
import { logError } from '../../../services/loggerService'
import MetricsService from '../../../services/metricsService'
import { SANDBOX_ACTION } from '../../../constants/metrics'
import { getCIUrls } from '../../../utils/auth.utils'

interface IDeleteConfirmationModalProps {
  bearerToken: string
  setNextSandboxToShow: Function
  selectedSandboxOrgId: string | null
  fetchSandboxData: Function
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModalProps> = ({
  bearerToken,
  setNextSandboxToShow,
  selectedSandboxOrgId,
  fetchSandboxData,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()
  const [isSendingRequest, setIsSendingRequest] = useState(false)

  const deleteSandboxRequest = async (): Promise<void> => {
    const { identityUrl } = getCIUrls()
    try {
      setIsSendingRequest(true)
      await httpService.delete(
        `${process.env.GATSBY_BFF_BASE_URL}/v1/sandbox/${selectedSandboxOrgId}`,
        {
          data: { identityHost: identityUrl },
          headers: { Authorization: `Bearer ${bearerToken}` },
        }
      )

      fetchSandboxData()
      metricsService.track(SANDBOX_ACTION, {
        type: 'Delete Sandbox Request',
      })
      setIsSendingRequest(false)
      setNextSandboxToShow('deleteSuccess')
    } catch (err) {
      setIsSendingRequest(false)
      logError(`the call to create sandbox failed due to ${err}`)
      setNextSandboxToShow('error')
    }
  }

  return (
    <>
      <ModalHeader headerLabel={`${t('sandbox.sandboxDeleteModalHeader')}`} />
      <ModalBody className="sandbox-modal-body" data-cy="delete-modal">
        <p>{`${t('sandbox.sandboxDeleteModalBody')}`}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={(): void => {
            setNextSandboxToShow('listSandboxes')
          }}
          ariaLabel={t('actionButtons.cancel')}
          color="default"
          data-cy="close-btn"
        >
          {t('actionButtons.cancel')}
        </Button>
        <Button
          onClick={deleteSandboxRequest}
          ariaLabel={`${t('sandbox.sandboxPageBtnProvision')}`}
          color="blue"
          data-cy="submit-btn"
          loading={isSendingRequest}
        >
          {`${t('actionButtons.submit')}`}
        </Button>
      </ModalFooter>
    </>
  )
}

export default DeleteConfirmationModal
