import React from 'react'
import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import { useTranslation } from 'react-i18next'
import ServiceUnavailableSvg from '../../../assets/svgs/service-unavailable.svg'

interface ISandboxErrorResponseProps {
  handleClose: Function
}

const SandboxErrorResponse: React.FC<ISandboxErrorResponseProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <ModalHeader headerLabel={t('errorPage.title')} />
      <ModalBody className="sandbox-modal-body" data-cy="modal-error">
        <ServiceUnavailableSvg />
        <p className="sandbox-response-text">
          {t('errorPage.bodyIntro')} {t('errorPage.bodyConclusion')}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={(): void => {
            handleClose()
          }}
          ariaLabel={t('actionButtons.close')}
          color="blue"
          data-cy="close-btn"
        >
          {t('actionButtons.close')}
        </Button>
      </ModalFooter>
    </>
  )
}

export default SandboxErrorResponse
