import { Button, ModalBody, ModalFooter, ModalHeader } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IDeniedSandboxModal {
  handleClose: Function
  email: string
}

const DeniedSandbox: React.FC<IDeniedSandboxModal> = ({
  handleClose,
  email,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <ModalHeader headerLabel={t('sandbox.sandboxRequestHeader')} />
      <ModalBody className="sandbox-modal-body">
        <p className="sandbox-modal-body-text">
          {t('sandbox.sandboxRequestBodyP1')}
          {email}
          {t('sandbox.sandboxRequestBodyP2')}
          <span className="sandbox-emphasis-text">
            {t('sandbox.sandboxRequestBodyP3')}
          </span>
          {t('sandbox.sandboxRequestBodyP4')}
        </p>
      </ModalBody>
      <ModalFooter>
        <div className="request-modal-buttons">
          <Button
            onClick={(): void => {
              handleClose()
            }}
            ariaLabel={t('actionButtons.back')}
            color="default"
            data-cy="back-btn"
          >
            {t('actionButtons.back')}
          </Button>
          <Button
            onClick={(): void => {
              handleClose()
            }}
            ariaLabel={t('actionButtons.close')}
            color="blue"
            data-cy="close-btn"
          >
            {t('actionButtons.close')}
          </Button>
        </div>
      </ModalFooter>
    </>
  )
}

export default DeniedSandbox
